<template>
  <svg viewBox="0 0 1000 620" xmlns="http://www.w3.org/2000/svg" width="450">
    <svg:style>
      .barTitle {
        font-family: "Roboto";
        font-size: 40px;
        fill: rgba(255, 255, 255, 0.5);
        font-weight: 300;
      }
      .barLabel {
        font-family: "Roboto";
        font-size: 30px;
        fill: #fff;
        font-weight: 300;
      }
    </svg:style>

    <rect x="0" y="0" width="1000" height="620" fill="#112748" />

    <text x="25" y="60" class="barTitle">{{ chartTitle }}</text>

    <text
      v-for="(item, index) in data"
      x="35"
      :y="startY + index * rowMultiple"
      class="barLabel"
    >
      {{ item[0] }}
    </text>

    <rect
      v-for="(item, index) in bars"
      :x="item.x"
      :y="item.y"
      :width="item.width"
      :height="item.height"
      :fill="item.color"
      rx="10"
    >
      <title>{{ `${item.value} Mentions` }}</title>
    </rect>
  </svg>
</template>
<script>
export default {
  name: "chart-bar",
  data: () => ({
    startY: 150,
    rowMultiple: 75,
    barHeightFactor: 0.8,
    startX: 300,
    endX: 900,
    bars: [[]],
  }),
  props: {
    chartTitle: {
      type: String,
      default: "Chart Title",
    },
    data: {
      type: Array,
      default: () => [[1]],
    },
  },
  mounted() {
    this.drawBars();
  },
  watch: {
    data() {
      this.drawBars();
    },
  },
  methods: {
    drawBars: function () {
      let barLength = this.endX - this.startX;
      let barHeight = this.rowMultiple * this.barHeightFactor;
      let horizontalSpace = 10;
      let totals = this.data.map((d) => d[1] + d[2]);
      let rectangles = [];
      const greatest = Math.max(...totals);
      let nextY = this.startY - barHeight / 2 - 10;
      let colorOne = "#82C1ED";
      let colorTwo = "#7C70EB";
      for (const orginalRow of this.data) {
        let row = [...orginalRow];
        row.shift(0); // Get rid of label data in position 0
        let nextX = this.startX;
        let color = colorOne;
        for (const datum of row) {
          let percent = datum / greatest;
          if (percent != 0 && percent < 0.15) {
            percent = 0.15;
          }
          let width = percent * barLength;
          rectangles.push({
            x: nextX,
            y: nextY,
            width: width,
            height: barHeight,
            color: color,
            value: datum,
          });
          color = colorTwo;
          nextX = nextX + width;
          if (width > 0) {
            nextX += horizontalSpace;
          }
        }
        nextY = nextY + this.rowMultiple;
      }
      this.bars = rectangles;
    },
  },
};
</script>
