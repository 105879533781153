<template>
  <v-card color="secondary" class="px-3 py-3">
    <v-row>
      <v-col cols="12">
        {{ name }}<br />
        {{ formatDate(startDate) }} - {{ formatDate(endDate) }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-icon medium color="accent">mdi-account-circle-outline</v-icon>
        <span class="pl-1">{{ authorCount }}</span>
      </v-col>
      <v-col cols="4">
        <v-icon medium color="accent">mdi-forum</v-icon>
        <span class="pl-1">{{ convoCount }}</span>
      </v-col>
      <v-col cols="4">
        <v-icon medium color="accent">mdi-message-text-outline</v-icon>
        <span class="pl-1">{{ messageCount }}</span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { formatDate } from "@/utils/common";
export default {
  name: "source-summary",
  data: () => ({
    formatDate: formatDate,
  }),
  props: {
    name: {
      type: String,
      default: "Name of Source",
    },
    startDate: {
      type: String,
      default: "startdate",
    },
    endDate: {
      type: String,
      default: "enddate",
    },
    authorCountFull: {
      type: Number,
      default: 1,
    },
    convoCountFull: {
      type: Number,
      default: 1,
    },
    messageCountFull: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    authorCount() {
      return this.kFormatterDecimal(this.authorCountFull);
    },
    convoCount() {
      return this.kFormatterDecimal(this.convoCountFull);
    },
    messageCount() {
      return this.kFormatterDecimal(this.messageCountFull);
    },
  },
  methods: {
    kFormatterDecimal(n) {
      n = Number(n);
      if (n < 1e3) return n;
      if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
      if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
      if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
      if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
    },
  },
};
</script>
