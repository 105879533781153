<template>
  <svg viewBox="0 0 1000 620" xmlns="http://www.w3.org/2000/svg" width="450">
    <svg:style>
      .pieTitle {
        font-family: "Roboto";
        font-size: 40px;
        fill: rgba(255, 255, 255, 0.5);
        font-weight: 300;
      }
      .pieLabel {
        font-family: "Roboto";
        font-size: 30px;
        fill: #fff;
        font-weight: 300;
      }
    </svg:style>
    <rect x="0" y="0" width="1000" height="620" fill="#112748" />
    <text x="25" y="60" class="pieTitle">{{ chartTitle }}</text>

    <path
      v-for="(item, index) in dataOneArcs"
      :key="index"
      fill="none"
      :stroke="item.strokeColor"
      :stroke-width="item.strokeWidth"
      :d="item.arcPath"
    >
      <title>{{ `${item.title} posts by ${item.label}` }}</title>
    </path>

    <path
      v-for="(item, index) in dataTwoArcs"
      :key="index + '2'"
      fill="none"
      :stroke="item.strokeColor"
      :stroke-width="item.strokeWidth"
      :d="item.arcPath"
    >
      <title>{{ `${item.title} posts by ${item.label}` }}</title>
    </path>

    <circle
      v-for="(item, index) in labels"
      :cx="labelsX"
      :cy="labelsY + labelR * 3 * (index - 1)"
      :r="labelR"
      :fill="item.color"
    />
    <text
      v-for="(item, index) in labels"
      class="pieLabel"
      :x="labelsX + (labelR / 2) * 3"
      :y="labelsY + labelR * 3 * (index - 1) + 8"
      font-size="30"
    >
      {{ item.text }}
    </text>
  </svg>
</template>

<script>
export default {
  name: "chart-pie",
  data: () => ({
    dataOneArcs: [],
    dataTwoArcs: [],
    centerX: 500,
    centerY: 310,
    arcX: 0,
    arcY: 0,
    labelsX: 500,
    labelsY: 310,
    labelR: 16,
    labels: [],
  }),
  props: {
    chartTitle: {
      type: String,
      default: "Chart Title",
    },
    dataOne: {
      type: Array,
      default: () => [],
    },
    labelsOne: {
      type: Array,
      default: () => [],
    },
    dataTwo: {
      type: Array,
      default: () => [],
    },
    labelsTwo: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.drawDataOne();
    this.drawDataTwo();
  },
  watch: {
    dataOne() {
      this.drawDataOne();
    },
    dataTwo() {
      this.drawDataTwo();
    },
  },
  methods: {
    drawDataOne: function () {
      const radius = 200;
      const strokeWidth = 50;
      this.arcX = radius + strokeWidth + 20;
      this.arcY = this.centerY + 30;
      const colors = ["#82C1ED", "#94C9F0", "#A6D2F2", "#B8DBF5", "#EDF6FC"];
      this.dataOneArcs = this.getArcs(
        this.dataOne,
        radius,
        strokeWidth,
        this.arcX,
        this.arcY,
        colors,
        this.labelsOne
      );

      this.labelsX = this.centerX + 150;
      this.labelsY = 150;
      this.labels = [];

      for (let [index, label] of this.labelsOne.entries()) {
        this.labels.push({
          text: label,
          color: colors[index],
        });
      }
    },
    drawDataTwo: function () {
      const radius = 130;
      const strokeWidth = 40;
      const colors = ["#7C70EB", "#8D82ED", "#9D94F0", "#ADA6F2", "#BEB8F5"];
      this.dataTwoArcs = this.getArcs(
        this.dataTwo,
        radius,
        strokeWidth,
        this.arcX,
        this.arcY,
        colors,
        this.labelsTwo
      );

      for (let [index, label] of this.labelsTwo.entries()) {
        this.labels.push({
          text: label,
          color: colors[index],
        });
      }
    },

    getArcs: function (arcData, radius, strokeWidth, x, y, colors, labels) {
      const arcArray = [];
      let lastSeenAngle = 0;
      let sum = 0;
      if (arcData.length > 0) {
        sum = arcData.reduce(function (a, b) {
          return a + b;
        });
      }
      let singleArc = false;
      if (arcData.length == 1) {
        singleArc = true;
      }
      for (let [index, value] of arcData.entries()) {
        const percentage = value / sum;
        const degrees = Math.round(365 * percentage);
        const arcStart = lastSeenAngle;
        let arcEnd = lastSeenAngle + degrees;
        if (arcEnd > 365) {
          arcEnd = 365;
        }
        lastSeenAngle = arcEnd;
        arcArray.push({
          arcPath: this.describeArc(x, y, radius, arcStart, arcEnd, singleArc),
          strokeColor: colors[index],
          strokeWidth: strokeWidth,
          title: value,
          label: labels[index],
        });
        if (singleArc) {
          arcArray.push({
            arcPath: this.describeArc(
              x,
              y,
              radius,
              arcStart - 5,
              arcEnd + 5,
              singleArc,
              true
            ),
            strokeColor: colors[index],
            strokeWidth: strokeWidth,
            title: value,
            label: labels[index],
          });
        }
      }
      return arcArray;
    },
    // https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle#18473154
    polarToCartesian: function (centerX, centerY, radius, angleInDegrees) {
      var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
      };
    },

    describeArc: function (
      x,
      y,
      radius,
      startAngle,
      endAngle,
      singleArc,
      gapArc
    ) {
      var start = this.polarToCartesian(x, y, radius, endAngle);
      var end = this.polarToCartesian(x, y, radius, startAngle);
      var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
      let sweepFlag = 0;
      if (singleArc) {
        sweepFlag = 1;
      }

      if (gapArc) {
        sweepFlag = 0;
        largeArcFlag = 0;
      }

      var d = [
        "M",
        start.x,
        start.y,
        "A",
        radius,
        radius,
        0,
        largeArcFlag,
        sweepFlag,
        end.x,
        end.y,
      ].join(" ");

      return d;
    },
  },
};
</script>

<style>
/* svg {
    cursor: pointer;
    } */
</style>
