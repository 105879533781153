<template>
  <svg
    :viewBox="`0 0 ${viewBoxWidth} ${viewBoxHeight}`"
    xmlns="http://www.w3.org/2000/svg"
    width="940"
  >
    <svg:style>
      .lineTitle {
        font-family: "Roboto";
        font-size: 40px;
        fill: rgba(255, 255, 255, 0.5);
        font-weight: 300;
      }
      .lineLabel {
        font-family: "Roboto";
        font-size: 30px;
        fill: #fff;
        font-weight: 300;
      }
      .lineYAxis {
        font-family: "Roboto";
        font-size: 30px;
        fill: rgba(255, 255, 255, 0.5);
        font-weight: 300;
      }
    </svg:style>

    <rect
      x="0"
      y="0"
      :width="viewBoxWidth"
      :height="viewBoxHeight"
      fill="#112748"
    />

    <text x="25" y="60" class="lineTitle">{{ chartTitle }}</text>

    <path
      :stroke="dataOneColor"
      stroke-width="5"
      :d="dataOnePath"
      fill="none"
    ></path>

    <path
      :stroke="dataTwoColor"
      stroke-width="5"
      :d="dataTwoPath"
      fill="none"
    ></path>

    <circle
      v-for="(item, index) in dataOnePoints"
      :cx="item[0]"
      :cy="item[1]"
      r="20"
      fill="rgba(0, 128, 0, 0)"
    >
      <title>{{ `${dataOne[index]} mentions of ${term1}` }}</title>
    </circle>

    <circle
      v-for="(item, index) in dataTwoPoints"
      :cx="item[0]"
      :cy="item[1]"
      r="20"
      fill="rgba(0, 128, 0, 0)"
    >
      <title>{{ `${dataTwo[index]} mentions of ${term2}` }}</title>
    </circle>

    <text v-for="(item, index) in yAxis" x="25" :y="item.y" class="lineYAxis">
      {{ item.label }}
    </text>

    <text
      v-for="(item, index) in xAxis"
      :x="item.x"
      :y="item.y"
      class="lineYAxis"
    >
      {{ item.label }}
    </text>

    <circle :cx="lineLabelX - 30" cy="50" r="16" fill="#82C1ED" />
    <text class="lineLabel" :x="lineLabelX" y="60" font-size="30">
      {{ term1 }}
    </text>

    <circle v-if="term2" :cx="lineLabelX - 30" cy="100" r="16" fill="#7C70EB" />
    <text v-if="term2" class="lineLabel" :x="lineLabelX" y="110" font-size="30">
      {{ term2 }}
    </text>
  </svg>
</template>

<script>
export default {
  name: "chart-line",
  data: () => ({
    gridMaxX: 0,
    tickDx: 10,
    viewBoxWidth: 2000,
    viewBoxHeight: 620,
    smoothing: 0.001,
    dataOnePoints: [],
    dataOnePath: "",
    dataOneColor: "#82C1ED",
    dataTwoPoints: [],
    dataTwoPath: "",
    dataTwoColor: "#7C70EB",
    chartMargin: 100,
    xAxis: [],
    yAxis: [],
  }),
  props: {
    chartTitle: {
      type: String,
      default: "Chart Title",
    },
    term1: {
      type: String,
      default: "Term One",
    },
    term2: {
      type: String,
      default: "Term Two",
    },
    dataOne: {
      type: Array,
      default: () => [],
    },
    dataTwo: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    lineLabelX() {
      const charWidth = 15;
      if (!this.term1) {
        this.term1 = "";
      }
      if (!this.term2) {
        this.term2 = "";
      }
      const longest =
        this.term1.length > this.term2.length ? this.term1 : this.term2;
      const rightEdge = this.viewBoxWidth - this.chartMargin;
      return rightEdge - charWidth * longest.length;
    },
    chartHeight() {
      return this.viewBoxHeight - this.chartMargin * 2;
    },
    chartWidth() {
      return this.viewBoxWidth - this.chartMargin * 2;
    },
    gridMaxY() {
      let allValues = this.dataOne.concat(this.dataTwo);
      let maxVal = Math.max(...allValues);
      if (maxVal == 0) {
        return 2;
      } else {
        return Math.ceil(maxVal / 10) * 10;
      }
    },
  },
  mounted() {
    this.dataOnePath = this.drawData(this.dataOne, this.dataOnePoints);
    let dataTwoSum = this.dataTwo.reduce(function (a, b) {
      return a + b;
    });
    if (dataTwoSum > 0) {
      this.dataTwoPath = this.drawData(this.dataTwo, this.dataTwoPoints);
    }
  },
  watch: {
    dataOne() {
      this.dataOnePath = this.drawData(this.dataOne, this.dataOnePoints);
      let dataTwoSum = this.dataTwo.reduce(function (a, b) {
        return a + b;
      });
      if (dataTwoSum > 0) {
        this.dataTwoPath = this.drawData(this.dataTwo, this.dataTwoPoints);
      }
    },
    dataTwo() {
      this.dataOnePath = this.drawData(this.dataOne, this.dataOnePoints);
      let dataTwoSum = this.dataTwo.reduce(function (a, b) {
        return a + b;
      });
      if (dataTwoSum > 0) {
        this.dataTwoPath = this.drawData(this.dataTwo, this.dataTwoPoints);
      }
    },
  },
  methods: {
    drawData: function (dataSet, points) {
      if (dataSet.length > this.gridMaxX) {
        this.gridMaxX = this.dataOne.length;
      }

      this.tickDx = this.chartWidth / this.gridMaxX;

      let nextTick = this.chartMargin;
      for (const point of dataSet) {
        let pointX = nextTick;
        nextTick = nextTick + this.tickDx;
        let dy = (point / this.gridMaxY) * this.chartHeight;
        let pointY = this.chartHeight + this.chartMargin - dy;
        points.push([pointX, pointY]);
      }

      let path = `M ${points[0][0]}, ${points[0][1]} `;

      for (let [index, point] of points.slice(1).entries()) {
        /* const controlPointStart = this.getControlPoint(points[index-1], points[index-2], point);
         * const controlPointEnd = this.getControlPoint(point, points[index-1], points[index+1], true); */
        /* path = path + `C ${controlPointStart[0]}, ${controlPointStart[1]} ${controlPointEnd[0]}, ${controlPointEnd[1]} ${point[0]}, ${point[1]}`; */
        path = path + `Q ${point[0]} ${point[1]} ${point[0]} ${point[1]}`;
      }
      this.drawXAxis();
      this.drawYAxis();
      return path;
    },
    drawXAxis: function () {
      const y = this.viewBoxHeight - this.chartMargin + 50;
      const startX = this.chartMargin + 10;
      const endX = this.viewBoxWidth - this.chartMargin - 60;
      const middleX = startX + (endX - startX) / 2;

      const xValues = [
        { x: startX, y: y, label: "60 Days Ago" },
        { x: middleX, y: y, label: "30 Days Ago" },
        { x: endX, y: y, label: "Today" },
      ];

      this.xAxis = xValues;
    },
    drawYAxis: function () {
      const startY = this.chartMargin + 10;
      const endY = this.viewBoxHeight - this.chartMargin;
      const middleY = startY + (endY - startY) / 2;

      const yValues = [
        { y: startY, label: this.gridMaxY },
        { y: middleY, label: Math.round(this.gridMaxY / 2) },
        { y: endY, label: 0 },
      ];

      const axisLabels = [];

      for (let [index, y] of yValues.entries()) {
        axisLabels.push({
          y: y,
          label: "label",
        });
      }
      this.yAxis = yValues;
    },

    // https://medium.com/@francoisromain/smooth-a-svg-path-with-cubic-bezier-curves-e37b49d46c74
    // https://archive.is/N0bB1
    getLine: function (pointA, pointB) {
      const lengthX = pointB[0] - pointA[0];
      const lengthY = pointB[1] - pointA[1];
      return {
        lineLength: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
        angle: Math.atan2(lengthY, lengthX),
      };
    },
    getControlPoint: function (
      currentPoint,
      previousPoint,
      nextPoint,
      endControlPoint = false
    ) {
      currentPoint = currentPoint || [0, 0];
      previousPoint = previousPoint || currentPoint;
      nextPoint = nextPoint || currentPoint;
      const oppossedLine = this.getLine(previousPoint, nextPoint);
      let angle = oppossedLine.angle;
      let lineLength = oppossedLine.lineLength * this.smoothing;
      if (endControlPoint) {
        angle = angle + Math.PI;
      }
      const x = currentPoint[0] + Math.cos(angle) * lineLength;
      const y = currentPoint[1] + Math.sin(angle) * lineLength;
      return [x, y];
    },
  },
};
</script>
