<template>
    <div>
        <v-row>
            <v-col cols="4">
                <v-row>
                    <v-col>
                        <span class="text-h3">{{
                            darkReconTotals.num_messages
                        }}</span>
                    </v-col>
                    <v-col>
                        <span class="primary--text dashboard-summary"
                            >UNIQUE<br />MESSAGES</span
                        >
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4">
                <v-row>
                    <v-col>
                        <span class="text-h3">{{
                            darkReconTotals.num_conversations
                        }}</span>
                    </v-col>
                    <v-col>
                        <span class="dashboard-summary primary--text"
                            >TOTAL<br />CONVOS</span
                        >
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4">
                <v-row>
                    <v-col>
                        <span class="text-h3">{{
                            darkReconTotals.num_authors
                        }}</span>
                    </v-col>
                    <v-col>
                        <span class="primary--text dashboard-summary"
                            >UNIQUE<br />AUTHORS</span
                        >
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <search-bar
                    :submitHandler="submitTrendsSearch"
                    placeholder="Trends Keyword One"
                    placeholderTwo="Trends Keyword Two"
                    :useTwoInputs="true"
                    :startValue="startValue"
                    :startValueTwo="startValueTwo"
                    :label="'Show Trends'"
                >
                </search-bar>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card
                    v-if="getMessages('term1').length < 3"
                    color="secondary"
                    height="0px"
                    width="100%"
                    flat
                >
                    <!--  <p class="chart-placeholder">
            Enter one or two search terms to observe deep web trends over the
            last 60 Days" Try: "virus" and "thanks"
          </p> -->
                </v-card>

                <v-card
                    v-if="getMessages('term1').length > 3 && !showTrends()"
                    color="secondary"
                    height="50px"
                    width="100%"
                    flat
                >
                    <p class="chart-no-results">
                        There were not instances of term(s). Please try
                        different terms.
                    </p>
                </v-card>

                <chart-line
                    v-if="getMessages('term1').length > 3 && showTrends()"
                    chartTitle="Mentions"
                    :dataOne="mentionsOne"
                    :dataTwo="mentionsTwo"
                    :term1="trendData.terms.term1"
                    :term2="trendData.terms.term2"
                >
                </chart-line>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-card
                    v-if="
                        authorData('term1').length == 0 &&
                        authorData('term2').length == 0
                    "
                    color="secondary"
                    height="0px"
                    width="100%"
                    flat
                >
                    <!-- <p class="chart-placeholder">Top Authors will be displayed here</p> -->
                </v-card>
                <chart-pie
                    c
                    v-if="
                        authorData('term1').length > 0 ||
                        authorData('term2').length > 0
                    "
                    :labelsOne="authorLabels('term1')"
                    :dataOne="authorData('term1')"
                    :labelsTwo="authorLabels('term2')"
                    :dataTwo="authorData('term2')"
                    chartTitle="Top Authors"
                ></chart-pie>
            </v-col>
            <v-col cols="6">
                <v-card
                    v-if="sourceData.length == 0"
                    color="secondary"
                    height="0px"
                    width="100%"
                    flat
                >
                    <!-- <p class="chart-placeholder">Top Sources will be displayed here</p> -->
                </v-card>

                <chart-bar
                    v-if="sourceData.length > 0"
                    chartTitle="Sources"
                    :data="sourceData"
                >
                </chart-bar>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="8">
                <search-bar
                    :submitHandler="submitSearch"
                    placeholder="Filter Dark Search Web Sources"
                    :useSearchButtonIcon="true"
                >
                </search-bar>
            </v-col>
            <v-col offset="1" cols="3">
                <sort-list
                    class="mt-12 float-right"
                    :items="items"
                    v-model="selectedSort"
                ></sort-list>
            </v-col>
        </v-row>

        <div style="min-height: 800px">
            <v-row>
                <v-col
                    v-for="(source, i) in darkReconSources.stats"
                    :key="i"
                    cols="4"
                >
                    <source-summary
                        :name="source.source_id"
                        :authorCountFull="source.num_authors"
                        :convoCountFull="source.num_conversations"
                        :messageCountFull="source.num_messages"
                        :startDate="source.earliest_message"
                        :endDate="source.latest_message"
                    >
                    </source-summary>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
/* import Chart from "chart.js";
 * Chart.defaults.global.defaultFontColor = "#fff";
 * Chart.defaults.global.defaultFontFamily = "Roboto";
 * Chart.defaults.global.defaultFontStyle = "300"; */
// Chart.defaults.global.defaultFontSize = '14';
import { numFormatComma } from "@/utils/common";
import store from "../store/index";

// import ChartHalfDonut from "@/components/ChartHalfDonut.vue";
import ChartBar from "@/components/ChartBar.vue";
import ChartLine from "@/components/ChartLine.vue";
import ChartPie from "@/components/ChartPie.vue";
// import ChartStackedBar from "@/components/ChartStackedBar.vue";
// import ChartMentions from "@/components/ChartMentions.vue";
import SearchBar from "@/components/SearchBar.vue";
import SortList from "@/components/SortList.vue";
import SourceSummary from "@/components/SourceSummary.vue";

export default {
    name: "DarkReconDashboard",
    components: {
        ChartBar,
        //  ChartHalfDonut,
        ChartLine,
        ChartPie,
        // ChartStackedBar,
        // ChartMentions,
        SearchBar,
        SourceSummary,
        SortList,
    },
    data: () => ({
        items: [
            "Latest Update",
            "First Update",
            "Most Authors",
            "Most Conversations",
            "Most Messages",
        ],
        selectedSort: "Latest Update",
        startValue: "",
        startValueTwo: "",
    }),
    mounted() {
        // darkrecon/search/trends?term1=thank%20you&term2=trump
        const params = new URLSearchParams(window.location.search);
        const term1 = params.get("term1");
        const term2 = params.get("term2");

        if (term1) {
            this.submitTrendsSearch([term1, term2]);
            this.startValue = term1;
        }

        if (term2) {
            this.startValueTwo = term2;
        }

        store.dispatch("darkRecon/getTotals");
        store.dispatch("darkRecon/dashboardSearch", {
            key: "queryStringDashboard",
            value: "",
        });
    },
    watch: {
        selectedSort: function (val) {
            const values = [
                ["Latest Update", "latest_message"],
                ["First Update", "earliest_message"],
                ["Most Authors", "authors"],
                ["Most Conversations", "conversations"],
                ["Most Messages", "messages"],
            ];

            const selected_filter = values.filter((v) => v[0] == val)[0][1];
            store.commit("darkRecon/replaceValue", {
                key: "sortByDashboard",
                value: selected_filter,
            });
            this.submitSearch();
        },
    },
    computed: {
        trendData() {
            return store.state.darkRecon.darkReconTrends;
        },
        sourceData() {
            console.log(this.trendData);
            let sources = this.trendData.sources.term1.map((obj) => obj.source);
            sources = new Set(
                sources.concat(
                    this.trendData.sources.term2.map((obj) => obj.source)
                )
            );
            let sourceList = [];
            for (const sourceName of sources) {
                let thisSource = [sourceName];
                let term1 = this.trendData.sources.term1.filter(
                    (obj) => obj.source == sourceName
                );
                if (term1[0]) {
                    thisSource.push(term1[0].message_count);
                } else {
                    thisSource.push(0);
                }

                let term2 = this.trendData.sources.term2.filter(
                    (obj) => obj.source == sourceName
                );
                if (term2[0]) {
                    thisSource.push(term2[0].message_count);
                } else {
                    thisSource.push(0);
                }
                sourceList.push(thisSource);
            }
            return sourceList;
        },
        darkReconTotals() {
            const darkReconTotals = store.state.darkRecon.darkReconTotals;
            for (let [key, val] of Object.entries(darkReconTotals)) {
                darkReconTotals[key] = numFormatComma(val);
            }
            return darkReconTotals;
        },
        queryString() {
            return store.state.darkRecon.queryStringDarkrecon;
        },
        darkReconSources() {
            return store.state.darkRecon.darkReconSources;
        },
        mentionsOne() {
            if (this.trendData.messages.length == 0) {
                return [];
            } else {
                return this.trendData.messages.map((msg) => msg["term1"]);
            }
        },
        mentionsTwo() {
            if (this.trendData.messages.length == 0) {
                return [];
            } else {
                return this.trendData.messages.map((msg) => msg["term2"]);
            }
        },
    },
    methods: {
        showTrends() {
            let terms1 = this.getMessages("term1");
            let terms2 = this.getMessages("term2");
            let sum1 = terms1.reduce(function (a, b) {
                return a + b;
            });
            let sum2 = terms2.reduce(function (a, b) {
                return a + b;
            });

            if (sum1 == 0 && sum2 == 0) {
                return false;
            } else {
                return true;
            }
        },
        getMessages(termNum = "term1") {
            if (this.trendData.messages.length == 0) {
                return [];
            } else {
                return this.trendData.messages.map((msg) => msg[termNum]);
            }
        },
        authorData(termNum = "term1") {
            if (this.trendData.authors[termNum].length == 0) {
                return [];
            } else {
                return this.trendData.authors[termNum].map(
                    (obj) => obj.message_count
                );
            }
        },
        authorLabels(termNum = "term1") {
            return this.trendData.authors[termNum].map((obj) => {
                return obj.author.author_name;
            });
        },
        submitSearch: function (queryString) {
            store.dispatch("darkRecon/dashboardSearch", {
                key: "queryStringDashboard",
                value: queryString,
            });
        },
        submitTrendsSearch: function (queryStrings) {
            let url = new URL(window.location);
            url.searchParams.set("term1", queryStrings[0]);
            url.searchParams.set("term2", queryStrings[1]);
            window.history.replaceState({}, "", url.toString());

            store.commit("darkRecon/replaceValue", {
                key: "queryStringTrends1",
                value: queryStrings[0],
            });
            store.commit("darkRecon/replaceValue", {
                key: "queryStringTrends2",
                value: queryStrings[1],
            });

            store.dispatch("darkRecon/trendsSearch", {
                key: "queryStringTrends1",
                value: queryStrings[0],
            });
        },
    },
};
</script>

<style>
.dashboard-summary {
    size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0.03125em;
}

.chart-placeholder {
    color: #748194;
    font-weight: 300;
    line-height: 50px;
    padding: 0 20px;
}

.chart-no-results {
    color: #fff;
    font-weight: 300;
    line-height: 50px;
    padding: 0 20px;
}
</style>
